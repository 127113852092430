import React, { Component } from 'react'

export default class PrivacyPolicy extends Component {
    render() {
        return (
            <div>
                privacy page
            </div>
        )
    }
}
