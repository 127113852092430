import React, { useState, useEffect } from 'react'
import { Icon, Confirm } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function RefreshProxyServer(props) {
    const [showConfirm, setConfirm] = useState(false);
    
    return (
        <Icon size="large" style={{margin:"10px"}} color="orange" name='refresh' link onClick={()=> setConfirm(!showConfirm)}>
            <Confirm
                open={showConfirm}
                content='Are you sure you want to refresh this server?'
                confirmButton="Confirm"
                onCancel={()=> setConfirm(!showConfirm)}
                onConfirm={()=> {
                    refreshServer(props.ip);
                    setConfirm(!showConfirm);
                }}
                size='mini'
            />
        </Icon>
    );
};

const refreshServer = async (ip) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/refresh/${ip}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return notify('success', 'Success', `${ip} successfully refreshed.`)
        
    } else {
        return notify('danger', 'Error', JSON.stringify(response.error));
    }
};

export default RefreshProxyServer;