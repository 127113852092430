import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Image, Button, Icon, Input, Menu } from 'semantic-ui-react';
import { Link, withRouter, Redirect, useHistory } from 'react-router-dom';
import { loginRequest, checkAuth } from '../api/auth';
import logo from '../assets/icon.png';
import '../styles/Login.css';
import { setUserData } from "../store/action/action";
import { notify } from '../utils/notification';

function Login(props) {
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const [redirect, setRedirect] = useState(false);
    const history = useHistory();

    useEffect(()=> {
        if (localStorage.getItem('user')) {
            checkAuth().then(res => {
                if (res.success) setRedirect(true)
                    else return;
            })
        }
    }, []);

    const onEnterKey = async (event) => {
    
        if (event.charCode === 13) {
            let email = emailRef.current.value;
            let password = passwordRef.current.value;

            if (!email || !password) return notify('danger', 'Missing Fields', 'Please fill out all required fields.');

            const response = await loginRequest(email, password);
            console.log(response)
            if (response.error) return notify('danger', 'Login Failed', 'Please try again or reset your password.');

            localStorage.setItem('user', JSON.stringify(response.success.user));
            //dispatch({ type: 'LOGIN', data: response.success.user});
            //props.login(response.success.user);
            setRedirect(true);
            return;
        }
    };

    return (
        <div className="relative font-sans text-primaryText overflow-hidden w-full h-full">
            {redirect ? <Redirect push to="/" /> : null}
            <div className="m-auto w-4/6 md:w-2/7 lg:w-2/7 bg-onPrimaryBg h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div className="grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <img className="inline ml-auto mr-auto w-24" src={logo}  />
                    <input 
                        ref={emailRef}
                        placeholder="Email"
                        spellcheck="false"
                        id="newIp"
                        onKeyPress={onEnterKey} 
                        autoFocus 
                        className="pt-2 rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setEmail(emailRef.current.value)}
                    />
                    <input 
                        ref={passwordRef}
                        type="password"
                        placeholder="Password"
                        id="newIp"
                        onKeyPress={onEnterKey}
                        className="rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setPassword(passwordRef.current.value)}
                    />
                    
                    <button onClick={()=> onEnterKey({charCode: 13})} className="font-medium p-1 bg-gradient-to-r from-primary to-secondary shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300">
                    Login
                    </button>
                    <div className="grid-rows-1 flex flex-wrap content-evenly">
                        <button onClick={() => history.push('/register')} className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto">
                            <text class="buttonText text-xs opacity-60">Create account</text>
                        </button>
                        <button onClick={() => history.push('/reset-password')} className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto">
                            <text class="buttonText text-xs opacity-60">Forgot password?</text>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    // return (
    //     <Container textAlign="center">
    //         {redirect ? <Redirect push to="/" /> : null}
    //         <div className="body">
    //             <Image centered size="tiny" src={logo} />
    //             <Container textAlign="center" style={{marginTop:"30px", width:"575px"}}>
    //                 <h3 class="fields">Email Address</h3>
    //                 <Input autoFocus type="email" size="small" placeholder="Email Address" onChange={(e)=> setLoginForm({...loginForm, email: e.target.value})} />
    //                 <h3 class="fields">Password</h3>
    //                 <Input type="password" size="small" placeholder="Password" onChange={(e)=> setLoginForm({...loginForm, password: e.target.value})}/>
    //             </Container>
    //             <Container style={{width:"575px", marginTop: "15px"}}>
    //                 <Menu secondary compact stackable widths="3">
    //                         <Menu.Item className="login">
    //                             <Button className="login" inverted color="blue" onClick={()=> {
    //                                 if (loginForm.email === null || loginForm.password === null) return notify('warning', 'Missing Fields', 'Please fill out all required fields.');
    //                                 loginRequest(loginForm.email, loginForm.password).then(response=> {
    //                                     if (response.error) return notify('danger', 'Login Failed', 'Please try again or reset your password.');
    //                                     props.setUserData(response.success.user);
    //                                     return setRedirect(true)
    //                                 });
    //                             }}>
    //                                 <text class="buttonText">
    //                                     Login
    //                                 </text>
    //                                 <Icon name="sign-in"/>
    //                             </Button>
    //                         </Menu.Item>
    //                         <Menu.Item>
    //                             <Button className="addUser" inverted color="green" as={ Link } to='/register'>
    //                                 <text class="buttonText">Register</text>
    //                                 <Icon name="add user"/>
    //                             </Button>
    //                         </Menu.Item>
    //                         <Menu.Item>
    //                             <Button className="forgotPassword" inverted color="red" as={ Link } to='/reset-password'>
    //                                 <text class="buttonText">Reset Password</text>
    //                                 <Icon name="redo"/>
    //                             </Button>
    //                         </Menu.Item>
    //                 </Menu>
    //             </Container>
    //         </div>
    //     </Container>
    // )
};

const mapDispatchToProps = dispatch => {
    return {
        setUserData: (user) => dispatch(setUserData(user))
    };
};

export default (withRouter(connect(null, mapDispatchToProps)(Login)));