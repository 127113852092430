export const createCheckoutSession = async (productName, productId, priceId, previousPage, quantity, duration, unitAmount) => {
    return fetch(process.env.REACT_APP_BACK_END + "/payment/create-checkout-session", {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          productName: productName,
          productId: productId,
          priceId: priceId,
          previousPage: previousPage,
          quantity: quantity,
          duration: duration,
          unitAmount: unitAmount
        })
      }).then(function(result) {
        return result.json();
      });
};

export const createCustomerPortalSession = async (event) => {
    // When the customer clicks on the button, redirect them to Checkout.
    const response = await fetch(process.env.REACT_APP_BACK_END + '/payment/create-customer-portal-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    })
      .then(res => res.json())
      .catch(error => error);

    return window.location = response.redirect;
};

