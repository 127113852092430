import React, { useState, useEffect } from 'react'
import { Container, Menu, Modal, Header, Button, Confirm } from 'semantic-ui-react';
import '../../styles/header.css';
import DeleteUser from './DeleteUser';

function EditUser(props) {
    const [open, setModal] = useState(false);

    return (
        <Modal size="large" closeIcon open={open} onClose={()=> setModal(false)} trigger={<Button onClick={()=> setModal(true)} icon="edit" />}>
            <Modal.Header>Edit User:</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <Header>{props.user.email}</Header>
                        <h3>Credits: {props.user.hasOwnProperty('credits') ? props.user.credits : 0}</h3>
                            <Container style={{textAlign: "center"}}>
                                <Menu secondary stackable widths="3">
                                    {/* <Menu.Item>
                                        <CreateProxyPackage email={props.user.email}/>
                                    </Menu.Item> */}
                                    <Menu.Item>
                                        <DeleteUser id={props.user._id} refreshUsers={props.refreshUsers} closeModal={()=> setModal(false)}/>
                                    </Menu.Item>
                                </Menu>
                            </Container>
                    </Modal.Description> 
                </Modal.Content>
        </Modal>
    );
};

let locationOptions = [
    {key: 1, text: 'New York', value: 'NewYork'},
    {key: 2, text: 'Los Angeles', value: 'Los Angeles'},
    {key: 3, text: 'Dallas', value: 'Dallas'},
    {key: 4, text: 'Virginia', value: 'Virginia'}
  ];

export default EditUser;