import React, { useEffect, useState } from 'react';
import { Container, Table, Loader } from 'semantic-ui-react';
import RefreshProxyServer from '../../components/admin/Proxies/RefreshProxyServer';
import PowerButton from '../../components/admin/Proxies/PowerButton';
import EditBlacklist from '../../components/admin/Proxies/EditBlacklistModal';
import BlacklistModal from '../../components/admin/Proxies/BlacklistsModal';
import { notify } from '../../utils/notification';

function Proxies(props) {
    const [proxyServers, setProxyServers] = useState([]);
    const [defaultBlacklist, setDefaultBlacklist] = useState({id: '', name: '', sites: []});

    useEffect(()=> {
        getProxyServers().then(servers => setProxyServers(servers));

        getDefaultBlacklist().then(data => {
            console.log(data)
            const defaultList = data.filter(list => list.name === 'default');
            if (defaultList.length === 0) return notify('danger', 'Error', 'Couldnt load default blacklist.');
            return setDefaultBlacklist({id: defaultList[0]._id, name: defaultList[0].name, sites: defaultList[0].sites});
        });
    }, []);
    console.log('RENDERED')
    return (
        <Container>
            <BlacklistModal defaultBlacklist={defaultBlacklist} refreshDefaultBlacklist={()=> {
                getDefaultBlacklist().then(data => {
                    console.log(data)
                    const defaultList = data.filter(list => list.name === 'default');
                    if (defaultList.length === 0) return notify('danger', 'Error', 'Couldnt load default blacklist.');
                    return setDefaultBlacklist({id: defaultList[0]._id, name: defaultList[0].name, sites: defaultList[0].sites});
                });
            }} />
            <Table celled inverted>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell>IP Address</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {proxyServers.length === 0 ? <Loader inverted size="large" active>Loading</Loader> : proxyServers.map((server)=> { 
                        return (
                            <Table.Row>                                       
                                <Table.Cell>{server.servername}</Table.Cell>
                                <Table.Cell>{server.mainip}</Table.Cell>
                                <Table.Cell collapsing>
                                    <PowerButton ip={server.mainip} squidStatus={server.squidstatus} reloadList={()=> getProxyServers().then(data => setProxyServers(data))} />
                                    <EditBlacklist ip={server.mainip} defaultBlacklist={defaultBlacklist.sites} />
                                    <RefreshProxyServer ip={server.mainip} />
                                </Table.Cell>
                            </Table.Row>
                            );                                 
                        })
                    }                    
                </Table.Body>
            </Table>
        </Container>
    )
};

export default Proxies;

const getProxyServers = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/proxies/server/list', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status !== 200) {
            return notify('danger', 'Error', `Failed with status: ${response.status}`)
        } else return response.json();
    }).catch(error => notify('danger', 'Error', error.message))
    
    return response.success;
};

const getDefaultBlacklist = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/proxies/blacklist/list', {
        method: 'POST',
        credentials: 'include'
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });
    
    return response;
};

// const getServersSquidStatus = async (ip) => {
//     const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/squid-status/list`, {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
//         credentials: 'include'
//     }).then(response => {
//         return response.json();
//     });

//     return response;
// };
