import React from 'react';
import sprint from '../assets/sprint.png';

export default function ProductCard(props) {
    console.log(props)
    return (
        <div class="mb-6 lg:my-4 lg:px-4 px-10 py-2 transform hover:-translate-y-4 hover:translate-x-1 duration-500">

         
            <article class="overflow-hidden rounded-lg shadow-lg w-full bg-onPrimaryBgSoft">

                <a href="#">
                    <img alt="Placeholder" class="block h-auto w-full" src={props.image}/>
                </a>

                <header class="p-2 md:p-4 text-center">
                    <h1 class="text-2xl font-sans font-medium">
                        {props.name}
                    </h1>
                </header>

                <div class="relative text-center">
                    <ul class="list-none bg-onPrimaryBgSoft text-primaryText inline-block">
                        {
                            props.description.map(e => (
                                <li class="p-3 font-sans font-normal text-left text-lg">
                                    <svg class="w-7 inline mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                    </svg>
                                    {e}
                                </li>
                            ))
                        }
                    </ul>
                </div>

                <footer class="w-full">
                    <div class="flex rounded-md mt-4">
                        <a href={`${process.env.REACT_APP_FRONT_END}/pricing`} class="hover:text-primaryText hover:opacity-70 duration-300 text-shadow shadow-md items-center text-center w-full h-12 m-4 pt-2.5 lg:pt-1 border border-transparent lg:text-2xl font-medium rounded-md text-primaryText bg-transparent bg-gradient-to-r from-primary to-secondary">
                            {props.duration}
                        </a>
                    </div>
                </footer>
            </article>
        </div>
    )
};
//w-full m-3 h-12 px-3 py-3 lg:h-15 lg:px-5 lg:py-3 border border-transparent lg:text-2xl font-medium rounded-md text-primaryText bg-gradient-to-r from-primary to-secondary