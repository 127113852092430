import React, { useEffect, useState } from 'react';
import ClipboardJS from "clipboard";
import { notify } from '../utils/notification';
import { formatDate } from '../utils/formatDate';
import './Scrollbar.css';



export default function ProxyList(props) {
  const [ proxies, setProxies ] = useState([]);
  const [ loading, setLoading ] = useState(true);

  useEffect(()=> {
    setProxies(props.package.length !== 0 ? props.package[0].proxies : []);
    const clipboard = new ClipboardJS(".copyProxyList");
    clipboard.on('success', function (e) {
      notify('info', 'Copied', 'Proxy list successfully copied.')
      return e.clearSelection();
    });
    return function cleanup(){ clipboard.destroy();}
  }, [props.package]);
  
  return (
    <div className="text-white text-center w-full pt-6 3xl:pt-40 font-sans h-screen">
      <div className="m-auto min-w-3xl w-6/7 lg:max-w-4xl bg-onPrimaryBg h-4xl pt-10 rounded-md">
      
        {
          proxies.length === 0 ? 
          <a
              href={`${process.env.REACT_APP_FRONT_END}/pricing`} 
              class={`${window.location.search.includes("settings") ? 'hidden' : ''} text-lg fixed mt-72 transform -translate-x-1/2 w-1/4 text-shadow bg-gradient-to-r from-primary to-secondary max-w-full rounded-md p-2 text-primaryText hover:text-primaryText hover:opacity-70 focus:outline-none duration-300`}>
                Purchase Proxy Plan
          </a>
          :
          <>
            <table className="w-full">
              <thead>
                <tr>
                  <th className="float-left px-14 font-semibold">Proxy List</th>
                </tr>
                <tr className="block border-b-2 border-onPrimaryBgSofter ml-10 mr-10 mt-5 mb-5 w-auto"></tr>
              </thead>
              <tbody id="proxyList" className="bg-onPrimaryBg scrollbar-thin scrollbar-track-onPrimaryBg scrollbar-thumb-onPrimaryBgSofter hover:scrollbar-thumb-onPrimaryBgSofter">
                {
                  proxies.map(e => {

                    return (
                      <tr className="animate-fade-in-down" key={e.ip || e}>
                        <td className="float-left px-14 text-onPrimaryBgSofter">{e.ip || e}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
            <div className="flex justify-between pt-12">
            <button className="copyProxyList bg-onPrimaryBg p-3 rounded-md text-lg font-medium w-full focus:outline-none outline-none transform hover:opacity-60 duration-300 select-none" data-clipboard-target="#proxyList">
              Copy
            </button>
          </div>
        </>
        }
      </div>
      <span className="text-onPrimaryBgSofter text-center font-sans bottom-0 right-0 p-6 fixed">{props.package.length === 0 ? null : `Expires: ${formatDate(props.package[0].expirationDate)}`}</span>
    </div>
  )
};

