import React, { useState, useEffect } from 'react';
import { Button, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { notify } from '../utils/notification';
import logo from '../assets/nano-logo.png';
import nanoGif from '../assets/nano-gif.gif'

const queryString = require('query-string');

function Success(props) {
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState('');
    const [proxyToken, setProxyToken] = useState('');

    useEffect(()=> {
        const { session_id } = queryString.parse(window.location.search);
        setLoading(true);
        getOrderData(session_id)
            .then(data => {
                console.log(data)
                setCustomer(data.customer.email);
                //setProxyToken(data.customer.subscriptions.data[0].metadata.authToken || '');
                setLoading(false);
            });
        
    }, []);

    if (loading) return <Loader active={loading} />;
    
    return (
        <div style={{textAlign: "center", marginTop: "150px"}}>
            
            <div className='text-center flex justify-center'>
                <h1 className='flex items-center' style={{fontSize: "60px"}}>Success! <img src={nanoGif} className="h-20 inline ml-5" /></h1>
            </div>
            
            <br/>
            
            <h2 style={{color: "white"}}>Thanks for your order, {customer}</h2><br />
            <Button onClick={()=> window.location = `${process.env.REACT_APP_FRONT_END}/dashboard`}>Manage Proxy Package</Button>
        </div>
    )
};

export default Success;

const getOrderData = (session_id) => {
    const response = fetch(process.env.REACT_APP_BACK_END + `/payment/order/success?session_id=${session_id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(res => res.json());

    return response;
};