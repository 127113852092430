import React, { useEffect, useState } from 'react'
import { Container, Image } from 'semantic-ui-react';
import '../../styles/header.css';

function AdminPanel (props) {
    let user = localStorage.getItem('user');
    let discordUser = localStorage.getItem('discordUser');
    user ? user = JSON.parse(user) : discordUser = JSON.parse(discordUser);

    const [gif, setGif] = useState(null)
    
    useEffect(() => {
        welcomingGif().then(data => setGif(data.data.image_original_url));
    }, []);

    return (
        <Container textAlign="center">
            <h1 style={{marginTop: "100px", wordSpacing: "5px", fontSize: "70px"}}>
                Welcome fellow admin, <h2 style={{fontSize: "70px", color: "#57FE76"}}>{user ? user.firstName : discordUser.username}</h2>
            </h1>
            <Image centered style={{marginTop: "100px"}} src={gif} />
        </Container>
    )
};

const welcomingGif = async () => {
    const response = await fetch('https://api.giphy.com/v1/gifs/random?api_key=JvOU65NXgSXrOPP8wwv1tt9AA9bzuHu2&tag=welcome')
        .then(response => response.json());
        
    console.log(response);
    return response;
};


export default AdminPanel;