import React, { useState, useEffect } from 'react'
import { Icon, Confirm } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function PowerButton(props) {
    const [showConfirm, setConfirm] = useState(false);
    
    console.log(props)
    return (
        <Icon size="large" style={{margin:"10px"}} color={props.squidStatus === 'online' ? "green" : "red"} name='power' disabled={props.squidStatus === 'failed' ? true : false} link onClick={()=> setConfirm(!showConfirm)}>
            <Confirm
                open={showConfirm}
                content={`Are you sure you want to power ${props.squidStatus === 'online' ? 'off' : 'on'} this server?`}
                confirmButton="Confirm"
                cancelButton="Cancel"
                onCancel={()=> setConfirm(!showConfirm)}
                onConfirm={()=> {
                    if (props.squidStatus === 'online') {
                        powerOffServer(props.ip).then(()=> props.reloadList());
                    } else {
                        powerOnServer(props.ip).then(()=> props.reloadList());
                    }
                    setConfirm(false);
                }}
                size='mini'
            />
        </Icon>
    );
};

const powerOffServer = async (ip) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/squid-off/${ip}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(response => {
        return response.json();
    });
    if (response.success) {

        return notify('success', 'Success', `${ip} successfully powered off server.`)
        
    } else {
        return notify('danger', 'Error', JSON.stringify(response.error));
    }
};

const powerOnServer = async (ip) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/squid-on/${ip}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return notify('success', 'Success', `${ip} successfully powered on server.`)
        
    } else {
        return notify('danger', 'Error', JSON.stringify(response.error));
    }
};

export default PowerButton;