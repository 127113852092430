import React, { useState, useEffect } from 'react';
import { Grid, Image, Card, Dropdown, List } from 'semantic-ui-react';

import '../../styles/CardSectionStyles.css';
import '../../styles/home.css';
import './DatacenterPackage.css';
import DatacenterCheckout from './DatacenterCheckout';

function DatacenterPackage(props) {
    const [ tier, setTier ] = useState(props.tiers[0]);
    const [ price, setPrice ] = useState(calculatePrice(props.tiers[0].up_to, props.tiers[0].unit_amount));
    const [ pricePerProxy, setPricePerProxy ] = useState((props.tiers[0].unit_amount / 100).toFixed(2))
    const [ numOfProxies, setNumOfProxies ] = useState(props.tiers[0].up_to);

    const handleNumOfProxyChange = (e) => {
        if (!e.target.textContent) return;
        if (e.target.textContent === '25255075') return;

        const numOfProxies = parseInt(e.target.textContent);
        const newTier = props.tiers.filter(tier => tier.up_to === numOfProxies);
        
        setTier(newTier[0]);
        setPrice(calculatePrice(newTier[0].up_to, newTier[0].unit_amount));
        setNumOfProxies(newTier[0].up_to);
        setPricePerProxy((newTier[0].unit_amount / 100).toFixed(2));
    };
    
    return (
        <div>
            <Card centered style={{margin:"15px"}}>
            <Image fluid src={props.image} />
                <Card.Content>
                    <Card.Header textAlign="center">{props.name}</Card.Header>
                    <Card.Description textAlign="center">
                        <Grid columns="equal">
                            <Grid.Row centered style={{marginTop:"10px"}}>
                                <h4 style={{margin:"7px"}}>Proxy Quantity: </h4>                
                                <Dropdown
                                    defaultValue={25}
                                    onChange={(e)=> {handleNumOfProxyChange(e)}}
                                    compact
                                    selection
                                    options={props.tiers.map(tier => {
                                        
                                        return {text: tier.up_to, value: tier.up_to}
                                    }).filter(item => item.text !== null)}
                                />
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column width={12}>
                                    <h2 style={{fontSize: "18px", fontWeight: "800", textAlign: "center"}}>{props.type}</h2>
                                    <List style={{textAlign: "center"}}>
                                        {
                                            !props.supportedSites ? null :
                                                JSON.parse(props.supportedSites).map(e => <List.Item>{e}</List.Item>)
                                        }
                                    </List>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <h2>
                                    <span style={{marginRight:"5px"}}>${price.toFixed(2)}</span> 
                                    <small style={{marginLeft:"-5px"}}>/{props.timeframe.toLowerCase()}</small>
                                </h2>
                            </Grid.Row>
                        </Grid>
                    </Card.Description>
                </Card.Content>
                <Card.Content extra textAlign="center">
                    <DatacenterCheckout productName={props.name} servername={props.servername} productId={props.productId} unitAmount={tier.unit_amount} priceId={props.priceId || tier.priceId} available={props.available} price={price} numOfProxies={numOfProxies} duration={props.timeframe} image={props.image} location={props.location}/>
                </Card.Content>
            </Card>     
        </div>
    )
};

const calculatePrice = (numOfProxies, stripeAmount) => {
    return (numOfProxies * (stripeAmount / 100).toFixed(2));
};

export default DatacenterPackage;
