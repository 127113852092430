export const getProxyOrders = async () => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/order/proxies', {
        method: 'POST',
        credentials: 'include'
    }).then(res => {
        if (res.status === 401) return window.location = '/logout';
        return res.json();
    });
    return response;
};

export const getProxySettings = async () => {
    console.log(process.env.REACT_APP_BACK_END )
    const response = await fetch(process.env.REACT_APP_BACK_END + '/order/proxies/settings', {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
    }).then(res => {
        if (res.status === 401) return window.location = '/logout';
        return res.json();
    });
    return response;
};

export const saveProxySettings = async (list) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/order/proxies/settings/save', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
            ips: list
        })
    }).then(res => {
        if (res.status === 401) return window.location = '/logout';
        return res.json();
    }).catch(error => console.log(error.message))
    return response;
};

export const pingProxies = async (proxies) => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/order/proxies/ping', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            proxies: proxies
        })
    }).then(res => {
        if (res.status === 401) return window.location = '/logout';
        return res.json();
    }).catch(error => console.log(error.message));
    
    return response;
};

export const getDatacenterStock = async () => {

    const response = await fetch(process.env.REACT_APP_BACK_END + '/proxy/datacenter/stock', {
      method: 'POST',
      credentials: 'include',
      //headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }).then(response => response.json());
  
    return response;
};

export async function whitelistIp(token, ips) {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/proxy/datacenter/whitelist', {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
            token: token,
            ips: ips
        })
      }).then(response => response.json());
    
    return response;
};

export const getDatacenterProducts = async () => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/product/list', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({category: 'datacenter'})
    }).then(response => {
        if (response.status == 401) {
            return window.location = '/logout'
        } else {
            return response.json();
        }
    });

    return response;
};

export const isStockAvailable = (stock, servername) => {
    let response = stock.filter(server => server.hasOwnProperty(servername));
    response = response.pop();
    
    if (!response) return console.log('servername not found.');
    if (response) {
        response = response[servername].filter(server => {
            if (server.proxies >= 25) {
                return server;
            };
        });
        
    } else return false;

    if (response.length > 0) {
        return true;
    } else {
        return false;
    };
};

export const isExactStockAvailable = (stock, servername, numOfproxies) => {
    let response = stock.filter(server => server.hasOwnProperty(servername));
    response = response.pop();
    
    if (!response) return console.log('servername not found.');
    if (response) {
        response = response[servername].filter(server => {
            if (server.proxies >= numOfproxies) {
                return server;
            };
        });
        
    } else return false;

    if (response.length > 0) {
        return true;
    } else {
        return false;
    };
};