import React, { useState } from 'react';
import { Container, Image, Button, Icon, Input, Menu } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { registerUser } from '../api/auth';
import { notify } from '../utils/notification';
import logo from '../assets/icon.png';
import '../styles/Login.css';

function Register() {
    //const [registerForm, setRegisterForm] = useState({email: null, firstPassword: null, secondPassword: null});
    const emailRef = React.useRef(null);
    const firstPasswordRef = React.useRef(null);
    const secondPasswordRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onEnterKey = async (event) => {
    
        if (event.charCode === 13) {
            let email = emailRef.current.value;
            let firstPassword = firstPasswordRef.current.value;
            let secondPassword = secondPasswordRef.current.value;

            try {
                if (!email || !firstPassword || !secondPassword) throw ('Please fill out all fields.');
                if (!email.includes('@') || !email.includes('.com')) throw ('Please insert a valid email.');
                if (firstPassword !== secondPassword) throw ('Passwords do not match.');
                if (!firstPassword || !secondPassword) throw ('Please fill out all fields.')
            } catch (error) {
              console.log(error)
              return notify('warning', 'Missing Fields', error.toString());
            };

            const response = await registerUser(email, firstPassword);

            setLoading(false);

            if (response.success) {
                notify('success', 'Success', "Please check your email to confirm registration.");
                return setTimeout(()=> {
                    return window.location = '/login';
                }, 5000)
            } else {
                return notify('danger', 'Error', JSON.stringify(response.error));
            }
        }
    };

    return (
        <div className="relative font-sans text-primaryText overflow-hidden w-full h-full">
            <div className="m-auto w-4/6 md:w-2/7 lg:w-2/7 bg-onPrimaryBg h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                <div className="grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                    <img className="inline ml-auto mr-auto w-24" src={logo}  />
                    <input 
                        ref={emailRef}
                        placeholder="Email"
                        spellcheck="false"
                        id="newIp"
                        onKeyPress={onEnterKey} 
                        autoFocus 
                        className="pt-2 rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setEmail(emailRef.current.value)}
                    />
                    <input 
                        ref={firstPasswordRef}
                        type="password"
                        placeholder="Password"
                        id="newIp"
                        onKeyPress={onEnterKey}
                        className="rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setPassword(passwordRef.current.value)}
                    />
                    <input 
                        ref={secondPasswordRef}
                        type="password"
                        placeholder="Verify password"
                        id="newIp"
                        onKeyPress={onEnterKey}
                        className="rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setPassword(passwordRef.current.value)}
                    />
                    
                    <button onClick={()=> onEnterKey({charCode: 13})} className="font-medium p-1 bg-gradient-to-r from-primary to-secondary shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300">
                    Register
                    </button>
                    <div className="grid-rows-1 flex flex-wrap content-evenly">
                        <button onClick={() => history.push('/login')} className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto">
                            <text class="buttonText text-xs opacity-60">Back to login</text>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Register;