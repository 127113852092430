import React, { useState, useEffect } from 'react';
import { Container, Modal, Button, Input, Dropdown } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function AddAuthedIp(props) {
    const [open, setModal] = useState(false);
    const [ip, setIp] = useState(null);

    return (
        <Modal closeIcon onClose={()=> setModal(false)} open={open} size="tiny" trigger={
            <Button size="big" primary onClick={()=> setModal(true)}>
                Add Authed IP
            </Button>
            }
        >
            <Modal.Header>Add Authed IP</Modal.Header>
            <Modal.Content>
                <Container>
                    <Input autoFocus style={{margin:"10px"}} fluid label="IPv4" onChange={(e)=> setIp(e.target.value)}/>
                    <Button style={{margin:"10px"}} fluid onClick={()=> {
                            if (!ip) return notify('danger', 'Error', `Please insert IPv4 you would like to authenticate.`)
                            addIp(ip).then(()=> props.refreshAuthedIps());
                            setIp(null);
                            
                            return setModal(false);
                        }}>
                        Auth IP
                    </Button>
                </Container>
            </Modal.Content>
        </Modal>
    );
};

const addIp = async (ip) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + '/admin/settings/authed-ips/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
            ipAddress: ip
        })
    }).then(response => {
        return response.json();
    });
    if (response.success) {
        return notify('success', 'Success', 'IP Successfully Added.')
    } else {
        return notify('danger', 'Error', `Could not add IP address: ${JSON.stringify(response.error)}`)
    }
};

export default AddAuthedIp;