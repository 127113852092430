import React, { Component } from 'react'

export default class TermsAndConditions extends Component {
    render() {
        return (
            <div>
                terms and conditions page
            </div>
        )
    }
}
