import * as actionTypes from "../action/actionTypes";

const INITIAL_STATE = {
    isLoggedIn: false,
    user: null
};

export default (state = INITIAL_STATE, action) => {
    if (localStorage.getItem('user') === 'undefined') return null;

    const user = JSON.parse(localStorage.getItem('user'));
   
    if (user) state = {...state, user: user};
    
    switch (action.type) {
        case actionTypes.SET_LOGGED_IN: {
            return { ...state, isLoggedIn: action.authStatus };
        }
        case actionTypes.SET_USER_DATA: {
            return { ...state, user: action.user };
        }
        default:
            return state;
    }
};