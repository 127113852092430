import React, { useState, useEffect } from 'react'
import { Container, Image, Button, Icon, Input, Menu, Grid} from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import { resetPassword, resetPasswordRequest } from '../api/auth';
import { notify } from '../utils/notification';
import logo from '../assets/icon.png';
import '../styles/Login.css';

const queryString = require('query-string');

function ResetPassword(props) {
    const emailRef = React.useRef(null);
    const passwordRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState({show: false, token: null});
    const history = useHistory();

    useEffect(()=> {
        const token = queryString.parse(props.location.search);
        if (token.code) return setCode({ show: true, token: token.code });
    }, []);

    const onEnterKey = async (event) => {
    
        if (event.charCode === 13) {
            if (!code.show && code.token === null) {
                setLoading(true);

                let email = emailRef.current.value;

                if (!email) {
                    return notify('warning', 'Missing Fields', 'Please insert a valid email.');
                };

                const response = await resetPasswordRequest(email);
                setLoading(false);

                if (response.success) {
                    return notify('success', 'Success', "Please check your email.");
                }
            } else if (code.show && code.token !== null) {
                setLoading(true);

                let password = passwordRef.current.value;

                if (!code || !password) {
                    return notify('warning', 'Error', "Please fill out all fields.");
                };

                const response = await resetPassword(code.token, password);
                setLoading(false);

                if (response.success) {
                    notify('success', 'Success', JSON.stringify(response.success));
                    
                    return setTimeout(()=> {return window.location = '/login'}, 2000)
                } else {
                    return notify('danger', 'Error', JSON.stringify(response.error));
                }
            }
        }
    };

    return (
        <div className="relative font-sans text-primaryText overflow-hidden w-full h-full">
                    <div className="m-auto w-4/6 md:w-2/7 lg:w-2/7 bg-onPrimaryBg h-2/5 rounded-md px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                        <div className="grid grid-cols-1 space-y-5 w-full ml-auto mr-auto m-auto px-10 py-8 fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
                            <img className="inline ml-auto mr-auto w-24" src={logo}  />
            {
                !code.show ?
                    <>
                        <input 
                            ref={emailRef}
                            placeholder="Email"
                            spellcheck="false"
                            id="newIp"
                            onKeyPress={onEnterKey} 
                            autoFocus 
                            className="pt-2 rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                            //onChange={(e)=> setEmail(emailRef.current.value)}
                        />
                        
                        <button onClick={()=> onEnterKey({charCode: 13})} className="font-medium p-1 bg-gradient-to-r from-primary to-secondary shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300">
                        Email reset link
                        </button>
                        <div className="grid-rows-1 flex flex-wrap content-evenly">
                            <button onClick={() => history.push('/login')} className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto">
                                <text class="buttonText text-xs opacity-60">Back to login</text>
                            </button>
                        </div>
                    </>
                 
            :   
                <>
                    <input 
                        ref={passwordRef}
                        placeholder="New password"
                        spellcheck="false"
                        type="password"
                        id="newIp"
                        onKeyPress={onEnterKey} 
                        autoFocus 
                        className="pt-2 rounded-md bg-onPrimaryBg text-onPrimaryBgSofter focus:outline-none outline-none placeholder-onPrimaryBgSofter w-full" 
                        //onChange={(e)=> setEmail(emailRef.current.value)}
                    />
                    
                    <button onClick={()=> onEnterKey({charCode: 13})} className="font-medium p-1 bg-gradient-to-r from-primary to-secondary shadow-md rounded-md text-lg focus:outline-none outline-none hover:opacity-70 transition duration-300">
                    Change password
                    </button>
                    <div className="grid-rows-1 flex flex-wrap content-evenly">
                        <button onClick={() => history.push('/login')} className="focus:outline-none outline-none hover:opacity-70 transition duration-300 mr-auto ml-auto">
                            <text class="buttonText text-xs opacity-60">Back to login</text>
                        </button>
                    </div>
                </>
            }
            </div>
        </div>
    </div>
    )
};

// {
//     !code.show ?
//     <Container textAlign="center">
//         <div style={{marginTop:"50px"}}>
//             <Image centered size="tiny" src={logo} />
//             <Container textAlign="center" style={{marginTop:"30px"}}>
//                 <h3 class="fields">Email Address</h3>
//                 <Input type="email" size="small" placeholder="Email Address" onChange={(e)=> setEmail(e.target.value)} />
//             </Container>
//             <Container style={{width:"575px", marginTop: "15px"}}>
//                 <Button loading={loading} className="addUser" inverted color="green" onClick={()=> submitPasswordReset()}>
//                     <text class="buttonText">Email Reset Link</text>
//                     <Icon name="mail outline"/>
//                 </Button> <br />
//                 <Button className="forgotPassword" inverted color="red" as={ Link } to='/login' style={{marginTop: "10px"}}>
//                     <text class="buttonText">Cancel</text>
//                     <Icon name="cancel"/>
//                 </Button>
//             </Container>
//         </div>
//     </Container>
// :   <Container textAlign="center">
//         <div style={{marginTop:"170px"}}>
//             <Image centered size="medium" src={logo} />
//             <Container textAlign="center" style={{marginTop:"50px", width:"575px"}}>
//                 <h3 class="fields">New Password</h3>
//                 <Input type="password" size="large" fluid placeholder="Password" onChange={(e)=> setPassword(e.target.value)} />
//             </Container>
//             <Container style={{width:"575px", marginTop: "15px"}}>
//                 <Menu secondary compact stackable widths="1">
//                         <Menu.Item>
//                             <Button loading={loading} fluid className="addUser" inverted color="green" onClick={()=> confirmPasswordReset()}>
//                                 <text class="buttonText">Confirm Password Change</text>
//                                 <Icon name="mail outline"/>
//                             </Button>
//                         </Menu.Item>
//                 </Menu>
//             </Container>
//         </div>
//     </Container>
// }

export default ResetPassword;