import React, { Component } from 'react'
import { Container, Loader } from 'semantic-ui-react';
import { notify } from '../utils/notification';
import { confirmRegistration } from '../api/auth';

const queryString = require('query-string');

export default class Contact extends Component {
    state = {loading: true};

    async componentDidMount() {
        let authToken = queryString.parse(window.location.search);
        console.log(authToken);

        if (!authToken.code) {
            notify('danger', 'Error', 'No code provided.', 'center');
            setTimeout(()=> {
                return window.location = '/';
            }, 3000)
        } else {
            return await this.sendCodeToCompleteRegistration(authToken.code);
        }
    };

    sendCodeToCompleteRegistration = async (code) => {
        
        const response = await confirmRegistration(code)
        this.setState({loading: false});
        if (response.success) {
            notify('success', 'Registration Complete', JSON.stringify(response.success), 'center');
          } else if (response.error) {
            notify('danger', 'Error', JSON.stringify(response.error), 'center');
          };
        return setTimeout(()=> {
            return window.location = '/login';
        }, 3000)
    };

    render() {
        return (
            <Container>
                <Loader active={this.state.loading} />
            </Container>
        )
    }
};

// class ConfirmEmailPage extends Component {
//     render(props) {
//         return (
//             <div className="ui center aligned container">
//                 <div class="ui three column centered grid container" style={{marginTop: '70px'}}>
//                     <div class="three column row">
//                         <div class="column">
//                             <img className="ui fluid centered image" src="https://i.ibb.co/6Bb6N1L/Nano-Logo-PNG.png" />
//                         </div>
//                         <div class="column">
//                             <img 
//                                 className="ui fluid centered image" 
//                                 src="https://i.ibb.co/kG7zS9D/collab.png" 
//                                 height="300px" 
//                                 width="300px"
                                
//                             />
//                         </div>
//                         <div class="column" style={{margin:"auto", width: "50%"}}>
//                             <h1 className="ui header" style={{color:'#E5E5E5'}}>
//                                 {`${this.props.firstName} ${this.props.lastName}`}
//                             </h1>
//                         </div>
//                     </div>
//                 </div>
//                     <button class="ui large button" onClick={()=> window.location = this.props.url} style={{marginTop: '40px', width: '400px'}}>
//                         Click to Confirm Email
//                     </button>
//             </div>
//         )
//     }
// };