import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getProxyOrders } from '../api/proxies';
import { notify } from '../utils/notification';
import Sidebar from '../components/Sidebar';
import ProxyList from '../components/ProxyList';
import Settings from '../components/Settings';
import ReactNotification from 'react-notifications-component';

function DatacenterPortal(props) {
    const [proxyPackages, setProxyPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editList, setEditList] = useState([]);
    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    };

    let query = useQuery();

    useEffect(()=> {
        setLoading(true);

        getProxyOrders().then(data => {
            
            let packs = data.success;
            
            let validPacks = [];

            for (const pack of packs) {
                if (pack.hasOwnProperty('category')) validPacks.push(pack);
            }
            validPacks.sort((a, b)=> {
                if (a.category.toLowerCase() < b.category.toLowerCase()) return 1;
                if (a.category.toLowerCase() > b.category.toLowerCase()) return -1;
                return 0;
            });
            
            validPacks.forEach(e => console.log(e.category));
            setProxyPackages(validPacks);
            setLoading(false);
        });
        
    }, [proxyPackages.length]);

    //if (loading) return 
        
    return (
        <div className="w-full absolute left-0">
            
            <ReactNotification className="fixed bottom-2 right-0" />
            <div className="h-screen absolute" style={{left: "394px"}}>
                <Sidebar packages={proxyPackages} />
            </div>
            
            <div className="absolute right-0 w-sidebarWidth h-full">
                <div className={`h-full`}>
                    {
                        loading ? <Loader hidden={!loading} /> : <ProxyList package={query.get("package") !== null ? proxyPackages.filter(e => e.authToken === query.get("package")) : []} />
                    }
                    
                </div>
                <div className={query.get("settings") !== null ? '' : 'hidden'}>
                    <Settings />
                </div>
            </div>
        </div>
    )
};

const Loader = ({ hidden }) => (
    <svg className={`animate-spin inline-block w-8 h-8 rounded-full text-white ${hidden ? 'hidden' : ''} fixed top-1/2 left-1/2`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  );

export default DatacenterPortal;

