import React, { useState, useEffect } from 'react'
import { Container, Image, Menu, Dropdown, Button, Icon, Input, Divider } from 'semantic-ui-react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import logo from '../../assets/nano-logo.png';
import mobileMenuPic from '../../assets/mobile_menu.png';
import '../../styles/header.css';

function AdminPanelNav(props) {
    let user = JSON.parse(localStorage.getItem('user')) || {email: ''};

    const [activeItem, setActiveItem] = useState('');
    const [mobileMenu, setMobileMenu] = useState(false);

    return (
        <div>   
            <Menu secondary size="huge">
                <Menu.Item header style={{marginLeft:"25px"}}>
                    <Image 
                        as={Link}
                        src={logo} 
                        style={{width: "100px", height: "100px"}}
                        width="252.1px" 
                        height="216.6px"
                        to="/"
                    />
                </Menu.Item>
                <Container textAlign="center">
                    <Menu secondary size="massive" widths="5" inverted>
                    <Menu.Item position="right" id="side-drawer">
                        <Dropdown
                            open = {mobileMenu} 
                            onClose={()=> setMobileMenu(false)}
                            icon={<Image 
                                    src={mobileMenuPic}
                                    size="mini" 
                                    alt="mobileMenu"
                                    onClick={()=> setMobileMenu(true)}
                                />}
                        >
                            <Dropdown.Menu direction="left">
                                <Dropdown.Item 
                                    as={ Link } 
                                    to='/admin/users'>
                                    Users
                                </Dropdown.Item>
                                <Dropdown.Item 
                                    as={ Link } 
                                    to='/admin/proxies'>
                                    Proxies
                                </Dropdown.Item> 
                            </Dropdown.Menu>
                        </Dropdown>   
                    </Menu.Item>
                            
                    <Menu.Item id="menuItem" style={{color:"#EEEEEE"}} as={ Link } name='Users' active={activeItem === 'users'} onClick={(e, { name })=> setActiveItem(name)} to='/admin/users' /> 
                    <Menu.Item id="menuItem" style={{color:"#EEEEEE"}} as={ Link } name='Proxies' active={activeItem === 'proxies'} onClick={(e, { name })=> setActiveItem(name)} to='/admin/proxies'/>
                    </Menu>
                </Container>
                <Menu.Item style={{marginRight:"50px"}} id="menuItem">
                    <Dropdown
                        text={user.email.toUpperCase()} 
                        style={{color:'#57FE76', fontWeight:"700", fontSize:"20px"}}
                    >   
                        <Dropdown.Menu direction="left" style={{marginTop:"20px"}}>
                            <Dropdown.Item 
                                as={ Link } 
                                to='/logout'>
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Item>
                
            </Menu>
        </div>
    );
};

export default AdminPanelNav;