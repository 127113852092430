import React, { useState, useEffect } from 'react'
import { Icon, Confirm } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function DeleteAuthedIp(props) {
    const [showConfirm, setConfirm] = useState(false);
    
    return (
        <Icon size="large" style={{margin:"10px"}} color="red" name='trash' link onClick={()=> setConfirm(true)}>
            <Confirm
                open={showConfirm}
                content='Are you sure you want to remove this IP address?'
                confirmButton="Confirm"
                onCancel={()=> setConfirm(false)}
                onConfirm={()=> {
                    deleteAuthedIp(props.id).then(()=> props.refreshAuthedIps());
                    setConfirm(false);
                }}
                size='mini'
            />
        </Icon>
    );
};

const deleteAuthedIp = async (id) => {
    const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/settings/authed-ips/delete/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        credentials: 'include'
    }).then(async response => {
        if (!response.ok) throw response;
        response = await response.json();
        return notify('success', 'IP Successfully Deleted.', response.success);
    }).catch(error => {

        return notify('danger', 'Could not delete IP Address');
    });
};

export default DeleteAuthedIp;