import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, Redirect } from 'react-router-dom';
import nanoLogo from '../assets/nano-logo.png';

function Dropdown({ isOpen, toggle, location }) {

    if (location.pathname.includes('/dashboard')) return null;
    if (location.pathname.includes('/admin')) return null;
    if (location.pathname.includes('/login')) return null;
    if (location.pathname.includes('/register')) return null;
    if (location.pathname.includes('/reset-password')) return null;
    if (location.pathname.includes('/password')) return null;
    if (location.pathname.includes('/success')) return null;
    
    return (
        <div class={ isOpen ? "z-10	absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" : "hidden"}>
            <div class="rounded-lg shadow-md bg-primaryBg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                <img class="h-lg w-auto" src={nanoLogo} alt=""/>
                </div>
                <div class="-mr-2">
                <button onClick={()=> toggle()} type="button" class="bg-onPrimaryBgSoft rounded-md p-3 inline-flex items-center justify-center text-primaryText hover:text-gray-500 hover:bg-primaryText focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                </div>
            </div>
            <div role="menu" aria-orientation="vertical" aria-labelledby="main-menu">
                <div class="px-2 pt-2 pb-3 space-y-1" role="none">
                <a href="/" class="block px-3 py-2 rounded-md text-base font-medium text-primaryText hover:text-gray-900 hover:bg-primaryText" role="menuitem">Home</a>
               
                <a href="#features" class="block px-3 py-2 rounded-md text-base font-medium text-primaryText hover:text-gray-900 hover:bg-primaryText" role="menuitem">Features</a>

                <a href="#pricing" class="block px-3 py-2 rounded-md text-base font-medium text-primaryText hover:text-gray-900 hover:bg-primaryText" role="menuitem">Pricing</a>

                <a href="#success" class="block px-3 py-2 rounded-md text-base font-medium text-primaryText hover:text-gray-900 hover:bg-primaryText" role="menuitem">Success</a>
                </div>
                <div role="none">
                <a href={`${process.env.REACT_APP_FRONT_END}/dashboard`} class="hover:text-onHoverPrimaryText block text-shadow w-full py-3 text-center font-medium text-primaryText bg-gradient-to-r from-primary to-secondary" role="menuitem">
                    Dashboard
                </a>
                </div>
            </div>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default (withRouter(connect(mapStateToProps)(Dropdown)));