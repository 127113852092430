import React, { useEffect, useState } from 'react'
import { Container, Header, Divider, Grid, Card, Loader} from 'semantic-ui-react';
import DatacenterPackage from '../components/Products/DatacenterPackage';
import { connect } from 'react-redux';
import '../styles/CardSectionStyles.css';
import '../styles/home.css';
import '../styles/datacenterPricing.css';
import { getDatacenterStock, isStockAvailable, getDatacenterProducts } from '../api/proxies';


function DatacenterPricing(props) {
    const [products, setProducts] = useState([]);
    const [proxyStock, setProxyStock] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        setLoading(true);
        getDatacenterProducts().then(products => {
            setProducts(products);
            setLoading(false);
        });
        
        getDatacenterStock().then(stock => {
            setProxyStock(stock);
        });
        
    }, []);
        
    return (
        <div className="home">
        <div className="title-content">
            <Container>
                <Header className="title" size='huge'>Proxy Plans</Header>
                <Divider section/>
            </Container>
            
        </div>
        <div>
        <Loader active={loading} inverted />
        
            <Grid columns="equal" stackable style={{margin: "50px"}}>
                <Grid.Row centered>
                    <Header id="monthly-plans" textAlign="center" className="title" style={{color:"white", margin:"20px", fontSize:"30px"}}>Daily Plans</Header>
                </Grid.Row>
                <Grid.Row centered>
                    <Card.Group stackable>
                        {/* {
                            products.map((product)=> {

                                for (const price of product.prices) {

                                    if (price.recurring === null) {
                                        price.tiers = [{ up_to: price.transform_quantity.divide_by, unit_amount: price.unit_amount }]
                                        console.log(price)
                                        return (
                                            <DatacenterPackage
                                                servername={product.metadata.servername}
                                                name={`24 Hours ${product.name}`} 
                                                available={product.metadata.active && isStockAvailable(proxyStock, product.metadata.servername)}
                                                type={product.metadata.hasOwnProperty('type') ? product.metadata.type : null} 
                                                supportedSites={product.metadata.hasOwnProperty('supportedSites') ? product.metadata.supportedSites : null}
                                                productId={product.id}
                                                priceId={price.id}
                                                location={product.metadata.location} 
                                                tiers={price.tiers} 
                                                timeframe={'Daily'} 
                                                image={product.images[0]}  
                                            />
                                        )
                                    }
                                };
                            })
                        } */}
                        {
                            products.map((product)=> {
                                let tiers = [];

                                for (const price of product.prices) {
                                    
                                    if (price.recurring === null) {
                                        tiers.push({ up_to: price.transform_quantity.divide_by, unit_amount: price.unit_amount, priceId: price.id });
                                    }
                                };

                                console.log(tiers);
                                if (tiers.length === 0) return;
                                tiers.sort((a, b)=> a.up_to - b.up_to);
                                
                                return (
                                    <DatacenterPackage
                                        servername={product.metadata.servername}
                                        name={`24 Hours ${product.name}`} 
                                        available={product.metadata.active && isStockAvailable(proxyStock, product.metadata.servername)}
                                        type={product.metadata.hasOwnProperty('type') ? product.metadata.type : null} 
                                        supportedSites={product.metadata.hasOwnProperty('supportedSites') ? product.metadata.supportedSites : null}
                                        productId={product.id}
                                        //priceId={price.id}
                                        location={product.metadata.location} 
                                        tiers={tiers} 
                                        timeframe={'Daily'} 
                                        image={product.images[0]}  
                                    />
                                )
                            })
                        }
                    </Card.Group>
                </Grid.Row>
                <Grid.Row centered>
                    <Header id="monthly-plans" textAlign="center" className="title" style={{color:"white", margin:"50px", fontSize:"30px"}}>Monthly Plans</Header>
                </Grid.Row>
                <Grid.Row centered>
                    <Card.Group stackable>
                    {
                            products.map((product)=> {

                                for (const price of product.prices) {

                                    if (price.recurring !== null) {

                                        return (
                                            <DatacenterPackage
                                                servername={product.metadata.servername}
                                                name={`${monthsToDays(price.recurring.interval_count)} Day ${product.name}`} 
                                                available={product.metadata.active && isStockAvailable(proxyStock, product.metadata.servername)}
                                                type={product.metadata.hasOwnProperty('type') ? product.metadata.type : null} 
                                                supportedSites={product.metadata.hasOwnProperty('supportedSites') ? product.metadata.supportedSites : null}
                                                productId={product.id}
                                                priceId={price.id}
                                                location={product.metadata.location} 
                                                tiers={price.tiers} 
                                                timeframe={'Monthly'} 
                                                image={product.images[0]}  
                                            />
                                        )
                                    }
                                };
                            })
                        }
                    </Card.Group>
                </Grid.Row>
                
            </Grid>
            </div>
        </div>
    )
};

const mapStateToProps = state => {
    return { status: state.status }
};

export default connect(mapStateToProps)(DatacenterPricing);

// const getDatacenterProducts = async () => {
//     const response = await fetch(process.env.REACT_APP_BACK_END + '/product/list', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
//         credentials: 'include',
//         body: JSON.stringify({category: 'datacenter'})
//     }).then(response => {
//         if (response.status == 401) {
//             return window.location = '/logout'
//         } else {
//             return response.json();
//         }
//     });
    
//     return response;
// };

const monthsToDays = (months) => {
    return months * 30;
};