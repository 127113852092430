import React, { useEffect, useState } from 'react';
import { Container, Header, Button, Icon, Popup, Input, Modal, Label, Loader } from 'semantic-ui-react';
import { notify } from '../../../utils/notification';

function EditBlacklistModal (props) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newSiteInput, setNewSiteInput] = useState('');
    const [form, setForm] = useState({ ip: '', sites: [] });

    const removesite = async siteToDelete => {
        const updatedBlacklist = form.sites.filter(site => site !== siteToDelete);
        
        return setForm({...form, sites: updatedBlacklist})
    };

    const addSite = async siteToAdd => {
        return setForm({...form, sites: [...form.sites, siteToAdd]});
    };

    const getCurrentBlacklist = async (ip) => {
        const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/get-blacklist/${ip}`, {
            method: 'POST',
            credentials: 'include'
        }).then(response => {
            if (response.status == 401) {
                return window.location = '/logout'
            } else {
                return response.json();
            }
        });

        return response;
    };
    
    const saveBlacklist = async (ip, sites) => {
        const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/update-blacklist/${ip}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({
                list: sites
            })
        }).then(response => {
            if (response.status == 401) {
                return window.location = '/logout'
            } else {
                return response.json();
            }
        });
        if (response.success) {
            notify('success', 'Success', JSON.stringify(response.success))
            
        } else {
            notify('danger', 'Error', JSON.stringify(response.success));
        }
        setOpen(false);
        return response;
    };

    const installBlacklist = async (ip) => {
        const response = await fetch(process.env.REACT_APP_BACK_END + `/admin/proxies/server/install-blacklist/${ip}`, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' }
        }).then(response => {
            if (response.status == 401) {
                return window.location = '/logout'
            } else {
                return response.json();
            }
        });
        console.log(response)
        setOpen(false);
        return response;
    };
    
    return (
        <Modal open={open} onClose={()=> setOpen(false)} trigger={<Icon size="large" style={{margin:"10px"}} color="blue" name='list alternate outline' link onClick={()=> {
                setOpen(true)
                setLoading(true)
                getCurrentBlacklist(props.ip).then(list => {
                    if (list.error) {
                        notify('danger', 'Error', JSON.stringify(list.error))
                        setLoading(false)
                        return setOpen(false);
                    }
                    setForm({...form, sites: list.success})
                    setLoading(false)
                });
            }} />}>
            <Modal.Header>Configure Blacklist</Modal.Header>
                {loading ? null : <Modal.Content scrolling>
                    <Header>Blacklisted Sites</Header>
                    <Container>
                        {form.sites.length === 0 ? <Button onClick={()=> installBlacklist(props.ip)}>Install Blacklist</Button> : 
                            <>
                            {form.sites.map(site => {
                                return (
                                    <Label size="large" style={{margin:"15px", verticalAlign:"middle"}}>
                                        {site} <Icon color="red" name="delete" onClick={()=> removesite(site)} />
                                    </Label>
                                )
                            })}
                        
                        
                            <Popup content='Add site to blacklist' trigger={
                                    <Modal size="tiny" trigger={<Button style={{verticalAlign:"middle"}} primary icon={"add"} />}>
                                        <Modal.Header>Add Site</Modal.Header>
                                        <Modal.Content>
                                            <Input 
                                                fluid 
                                                action={
                                                    <Button onClick={()=> addSite(newSiteInput)}>
                                                    Add Site
                                                    </Button>}  
                                                placeholder='ex: .supremenewyork.com'
                                                onChange={(e) => setNewSiteInput(e.target.value)}
                                            />
                                        </Modal.Content>
                                    </Modal>
                                }
                            />
                            </>
                        }
                    </Container>        
                </Modal.Content>}
            <Modal.Actions>
            <div style={{textAlign:"center"}}>
                <Button style={{margin:"15px"}} onClick={()=> setForm({ ...form, sites: ['.unknownproxies.com'] })} color='red'>
                    <Icon name='ban' /> Clear List
                </Button>
                <Button style={{margin:"15px"}} onClick={()=> setForm({ ...form, sites: props.defaultBlacklist })} color='blue'>
                    <Icon name='redo' /> Restore Default List
                </Button>
                <Button style={{margin:"15px"}} onClick={()=> setOpen(false)} color='red'>
                    <Icon name='remove' /> Cancel Edits
                </Button>
                <Button style={{margin:"15px"}} color='green' onClick={()=> saveBlacklist(props.ip, form.sites)}>
                    <Icon name='checkmark' /> Save Configuration
                </Button>
            </div>
            </Modal.Actions>
        </Modal>
    )
};

export default EditBlacklistModal;

