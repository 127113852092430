import * as actionTypes from './actionTypes';

export const setLoggedIn = (authStatus) => {
    return {
      type: actionTypes.SET_LOGGED_IN,
      authStatus: authStatus
    };
};

export const setUserData = (user) => {
  
  return {
    type: actionTypes.SET_USER_DATA,
    user: user
  };
};